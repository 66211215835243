import PricelineLogo from 'icons/PricelineLogo'
import DriveLogo from 'icons/DriveLogo'
import ItalicLogo from 'icons/ItalicLogo'
import HashnodeLogo from 'icons/HashnodeLogo'
import PumaLogo from 'icons/PumaLogo'
import NasaLogo from 'icons/NasaLogo'
import ImmoCheckoutLogo from 'icons/ImmoCheckoutLogo'

const companyList: {
  name: string
  href: string
  logo: JSX.Element
}[] = [
  {
    name: 'Puma',
    href: 'https://puma.com',
    logo: (
      <PumaLogo className='h-6 w-full object-contain fill-current lg:h-9' />
    ),
  },
  {
    name: 'Priceline',
    href: 'https://priceline.com',
    logo: (
      <PricelineLogo className='h-6 w-full object-contain fill-current lg:h-9' />
    ),
  },
  {
    name: 'Nasa',
    href: 'https://github.com/nasa/cmr-graphql/blob/main/src/graphql/handler.js#:~:text=stellate',
    logo: (
      <NasaLogo className='h-6 w-full object-contain fill-current lg:h-9' />
    ),
  },
  {
    name: 'Drive.com.au',
    href: '/case-study/cost-savings-at-drive',
    logo: (
      <DriveLogo className='h-6 w-full object-contain fill-current lg:h-9' />
    ),
  },
  {
    name: 'Italic',
    href: '/blog/italic-case-study',
    logo: (
      <ItalicLogo className='h-6 w-full object-contain fill-current lg:h-9' />
    ),
  },
  {
    name: 'Hashnode',
    href: 'https://engineering.hashnode.com/understanding-graphql-error-codes-with-stellate-aws-lambda-api-gateway-and-apollo',
    logo: (
      <HashnodeLogo className='h-6 w-full object-contain fill-current lg:h-9' />
    ),
  },
  {
    name: 'Immo Checkout',
    href: 'https://www.immocheckout.com/',
    logo: (
      <ImmoCheckoutLogo className='h-6 w-full object-contain fill-current lg:h-9' />
    ),
  },
]

export function TrustedByCompanies() {
  return (
    <div className='pt-10 pb-20 lg:pt-20'>
      <div className='space-y-8'>
        <div className='text-14 text-center text-balance uppercase font-demibold tracking-wider'>
          <span className='bg-clip-text text-transparent bg-gradient-colorful-text'>
            Powering GraphQL APIs for Startups and Enterprises Across Industries
          </span>
        </div>
        <div className='grid grid-cols-2 max-w-88 mx-auto items-center gap-x-8 gap-y-8 sm:max-w-130 sm:grid-cols-4 lg:max-w-240'>
          {companyList.map((company, index) => (
            <a
              href={company.href}
              target='_blank'
              rel='noreferrer'
              aria-label={`Go to ${company.name}'s website`}
              key={`company-${index}`}
              className='text-neutral'
            >
              {company.logo}
            </a>
          ))}
        </div>
      </div>
    </div>
  )
}
